<template>
 <div>
   <a-modal title="确认商品" v-model="visible" @ok="onNextStep" width="65%" okText="确定" :maskClosable="false">
     <a-table :data-source="tableData" :columns="columns" :pagination="page" bordered @change="changeTable" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
       <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px;" :value="item"></ZoomMedia>
        </span>
     </a-table>
   </a-modal>
 </div>
</template>

<script>
 export default {
  data() {
    return {
      visible: false,
      tableData: [],
      columns: [
        {
          title: '商品名称',
          dataIndex: 'name',
          // width: 200,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '标签图标',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '分类',
          dataIndex: 'categoryName',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '型号',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '建议零售价',
          dataIndex: 'sellPrice',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '政策&折扣',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
        {
          title: '单位',
          dataIndex: '',
          // width: 120,
          ellipsis:true,
          align: 'center'
        },
      ],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
   props: {
     dataList: {
       type: Array,
       default: []
     },
     dealerId: {
       type: Number,
       default: 0
     }
   },
  created() {},
  methods: {
    isShow(data) {
      this.visible = true
      this.tableData = data
      console.log('dataList---', data)
    },
    // 分页
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },
    // 选中数据
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows
    },
    // 提交数据
    onNextStep() {

    },
  }
 }
</script>

<style scoped>

</style>